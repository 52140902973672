.toolbar {
  line-height: 1;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.toolbar a {
  display: inline-block;
  line-height: 1;
  background: #fff;
  padding: 4px;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}

.toolbar a:first-child {
  border-radius: 4px 0 0 4px;
}

.toolbar a:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 0;
}

.toolbar a:hover {
  background: #f4f4f4;
}
